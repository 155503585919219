
import {
	CR_ROLE,
	PRJT_ORG_ROLE,
	ORG_TYPE,
	PRJT_STAGE,
	DEPT_TYPE,
	SITE_STATE,
	MON_TYPE,
	MONDAY_STATE,
	MONVERI_STATE,
	MONTOPIC_CLASS,
	MONTOPIC_ANSWER,
	MVR_STATE,
	STONE_CRUCIAL,
	STONE_LV,
	STONE_SUIT_STATE,
	STONE_TYPE,
	RESEARCH_STAGE,
	LAW_STATE,
	LAW_TYPE,
	ORGREG_STATE,
	PRODOUCT,
	DOC_TYPE,
	DOC_ROLE,
	REMARK_STATE,
	LANGUAGE_MODEL,
	SMSNOTIWDOC_TYPE,
	DOC_STATE,
	DOC_FEE_STATE,
	Doc_FILE_WAY,
} from './consts.js'

import {genMap, genSMap, foldToObjs, addOrderedId} from './utils/xyhUtils'

function genOpts(...data) { return foldToObjs(data, 'id', 'name') }
function genOptsAutoId(...data) { return addOrderedId(foldToObjs(data, 'name')) }

const Prodoucts = genOpts(
	PRODOUCT.stone, '里程碑',
	PRODOUCT.sop, '质控',
	PRODOUCT.docLib, '平台文档库'
)

const DocTypes = genOpts(
	DOC_TYPE.policy, '政策文件',
	DOC_TYPE.manual, '质量手册',
	DOC_TYPE.sop, 'sop',
	DOC_TYPE.file, '支持文件',
	DOC_TYPE.annex, '附件',
)

// Note: 可以添加，不能插入
const StoneRoleTypes = genOptsAutoId(
	'必须且仅一人',
	'最多一人',
	'最少一人',
	'任意多人',
	'按所选角色顺序',
)

// Note: 可以添加，不能插入
const PrjtStoneStages = genOpts(
	RESEARCH_STAGE.preparing,'研究准备和启动阶段',
	RESEARCH_STAGE.doing,'研究进行阶段',
	RESEARCH_STAGE.done,'研究结束',
	RESEARCH_STAGE.all,'全部研究阶段',
)

const prjtStages = genOpts(
	RESEARCH_STAGE.preparing,'',
	RESEARCH_STAGE.doing,'(研究第一个中心启动-DBL)',
	RESEARCH_STAGE.done,'(DBL之后)',
	RESEARCH_STAGE.all,'',
)

const SiteStages = genOpts(
	RESEARCH_STAGE.preparing,'',
	RESEARCH_STAGE.doing,'(中心FSI-LPO)',
	RESEARCH_STAGE.done,'(LPO之后)',
	RESEARCH_STAGE.all,'',
)

const StoneCrucials = genOpts(
    STONE_CRUCIAL.very,'关键',
    STONE_CRUCIAL.modest,'非关键',
    STONE_CRUCIAL.oth,'其他',
)

const StoneSuitStates = genOpts(
	STONE_SUIT_STATE.editing,'未发布',
	STONE_SUIT_STATE.available,'已发布',
	STONE_SUIT_STATE.closed,'已关闭',
)

// Note: 可以添加，不能插入
const SiteStoneStages = genOpts(
	RESEARCH_STAGE.preparing,'中心准备和启动阶段',
	RESEARCH_STAGE.doing,'中心进行阶段',
	RESEARCH_STAGE.done,'中心关闭',
	RESEARCH_STAGE.all,'全部中心阶段',
)

// Note: 可以添加，不能插入
const StoneTypes = genOpts(
	STONE_TYPE.must1, '单次打卡(每个研究只有一次打卡机会)',
	// STONE_TIMES_TYPES.max1, '最多一次',
	// STONE_TIMES_TYPES.min1, '至少一次',
	STONE_TYPE.any, '多次打卡(根据情况打卡，次数不限制)',
	STONE_TYPE.monthly, '周期性打卡(每月一次)',
	STONE_TYPE.dmonthly, '周期性打卡(每两月一次)',
	STONE_TYPE.seasonly, '周期性打卡(每季度一次)',
	STONE_TYPE.yearly, '周期性打卡(每年一次)',
)

const BriefStoneTypes = genOpts(
	STONE_TYPE.must1, '单次',
	STONE_TYPE.any, '多次',
	STONE_TYPE.monthly, '每月',
	STONE_TYPE.dmonthly, '每两月',
	STONE_TYPE.seasonly, '每季度',
	STONE_TYPE.yearly, '每年',
)

// Note: 可以添加，不能插入
const StoneLvs = genOpts(
	STONE_LV.org,'公司层面',
	STONE_LV.prjt,'项目层面',
	STONE_LV.site,'所有中心',
	STONE_LV.siteLeader,'组长单位',
	STONE_LV.siteNonleader,'非组长单位',
)

const BriefStoneLvs = genOpts(
	STONE_LV.prjt,'项目',
	STONE_LV.site,'所有中心',
	STONE_LV.siteLeader,'组长单位',
	STONE_LV.siteNonleader,'非组长单位',
)

const PrjtStages = genOpts(
	PRJT_STAGE.I, 'I期' ,
	PRJT_STAGE.II, 'Ⅱ期' ,
	PRJT_STAGE.III, 'Ⅲ期' ,
	PRJT_STAGE.IV, 'Ⅳ期' ,
	PRJT_STAGE.PK, 'PK' ,
	PRJT_STAGE.BE, 'BE' ,
	PRJT_STAGE.other, '其他'
)

const CrRoles = genOpts(
	CR_ROLE.CPM, '项目经理' ,
	CR_ROLE.auditor, '稽查员' ,
	CR_ROLE.CRA, '监查员' ,
	CR_ROLE.CRC, '临床协调员' ,
	CR_ROLE.CRN, '研究护士' ,
	CR_ROLE.PI, '主要研究者' ,
	CR_ROLE.SI, '辅助研究者' ,
	CR_ROLE.CI, '合作研究者' ,
	CR_ROLE.DM, '药品管理员' ,
	CR_ROLE.inst, '机构办管理者' ,
	CR_ROLE.other, '其他',
    CR_ROLE.director, '总监' ,
	CR_ROLE.PMgr, '项目主管' ,
	CR_ROLE.medical, '医学' ,
	CR_ROLE.QA, '质控' ,
	CR_ROLE.Reg, '注册' ,
	CR_ROLE.CTA, 'CTA' ,
)

const PrjtOrgRoles = genOpts(
	PRJT_ORG_ROLE.sponsor, '申办方' ,
	PRJT_ORG_ROLE.CRO, 'CRO公司' ,
	PRJT_ORG_ROLE.SMO, 'SMO公司' ,
	PRJT_ORG_ROLE.institution, '研究机构' ,
	PRJT_ORG_ROLE.chief, '组长研究机构' ,
	PRJT_ORG_ROLE.other, '其他'
)

const OrgTypes = genOpts(
	ORG_TYPE.hospital,'医院',
	ORG_TYPE.drugCom,'药企',
	ORG_TYPE.CRO,'CRO公司',
	ORG_TYPE.SMO,'SMO公司',
	ORG_TYPE.com,'其他企业',
	ORG_TYPE.group,'群或自由团队',
	ORG_TYPE.other,'其他'
)

const DeptTypes = genOpts(
	DEPT_TYPE.treat,'医院科室',
	DEPT_TYPE.HOO,'医院机构办',
	DEPT_TYPE.qa,'QA',
	DEPT_TYPE.other,'其他',
)

const SiteStates = genOpts(
	SITE_STATE.init, "筛选中",
	SITE_STATE.toopen, "待启动",
	SITE_STATE.working, "进行中",
	SITE_STATE.closed, "已关闭",
)

const MonTypes=genOpts(
	MON_TYPE.filterSite, "中心筛选",
	MON_TYPE.openSite, "中心启动",
	MON_TYPE.transfer, "研究交接",
	MON_TYPE.closeSite, "中心关闭",
	MON_TYPE.check, "稽查",
	MON_TYPE.common, "常规监查",
	MON_TYPE.together, "协同监查",
	MON_TYPE.gov, "药政核查",
	MON_TYPE.govNMPA, "药政核查-NMPA",
	MON_TYPE.govFDA, "药政核查-FDA",
	MON_TYPE.govEMA, "药政核查-EMA",
	MON_TYPE.govOther, "药政核查-其他",
	MON_TYPE.other, "其他监查",
)

const MonDayStates=genOpts(
	MONDAY_STATE.todo, "待完成",
	MONDAY_STATE.done, "已完成",
	MONDAY_STATE.pass, "已核实",
	MONDAY_STATE.partPass, "有未过核实",
	MONDAY_STATE.canceled, "已取消",
	MONDAY_STATE.nopass, "核实不通过",
)

const MonVeriStates=genOpts(
	MONVERI_STATE.none, "待完成",
	MONVERI_STATE.pass, "已核实",
	MONVERI_STATE.nopass, "核实不通过",
	MONVERI_STATE.relless, "无关",
)

const MonTopic_Classes=genOpts(
	MONTOPIC_CLASS.c1, "方案违背",
	MONTOPIC_CLASS.c2, "知情同意",
	MONTOPIC_CLASS.c3, "数据核对",
	MONTOPIC_CLASS.c4, "人员资质",
	MONTOPIC_CLASS.c5, "安全性",
	MONTOPIC_CLASS.c6, "研究文件",
	MONTOPIC_CLASS.c7, "研究药物",
	MONTOPIC_CLASS.c8, "随机化",
	MONTOPIC_CLASS.c9, "设备场所",
	MONTOPIC_CLASS.c10, "生物样本",
)

const MonTopic_Answers=genOpts(
	MONTOPIC_ANSWER.yes, "是",
	MONTOPIC_ANSWER.not, "否",
	MONTOPIC_ANSWER.noavail, "不适用",
)

const MvrStates=genOpts(
	MVR_STATE.editing, '编辑中',
	MVR_STATE.editing2, '重新编辑中',
	MVR_STATE.blocked, '未通过',
	MVR_STATE.submitted, '已提交',
	MVR_STATE.submitted2, '二次提交',
	MVR_STATE.passed, '已通过',
)

const LawStates = genOpts(
	LAW_STATE.drafting, '起草中',
	LAW_STATE.advising, '征求意见中',
	LAW_STATE.released, '已发布',
	LAW_STATE.vaild, '已生效',
)

const LawTypes = genOpts(
	LAW_TYPE.law, '法律',
	LAW_TYPE.regulation, '管理条例',
	LAW_TYPE.deptRegu, '部门规章',
	LAW_TYPE.guide, '指导原则',
	LAW_TYPE.admRegu, '行政条例',
	LAW_TYPE.other, '其他',
)

const OrgRegStates = genOpts(
	ORGREG_STATE.drafting, '草稿中',
	ORGREG_STATE.rejected, '被拒绝',
	ORGREG_STATE.submitted, '已提交',
	ORGREG_STATE.accepted, '被接受',
)

const DocRoles = genOpts(
	DOC_ROLE.PM, '项目经理',
	DOC_ROLE.author, '作者',
	DOC_ROLE.translator, '翻译',
	DOC_ROLE.auditor, '审阅者',
)

const RemarkStates = genOpts(
	REMARK_STATE.normal, '未关闭',
	REMARK_STATE.accepted, '已接受',
	REMARK_STATE.rejected, '已拒绝',
	REMARK_STATE.canceled, '已取消',
	REMARK_STATE.closed, '已关闭',
)

const LanguageModels = genOpts(
	LANGUAGE_MODEL.Cn, '中文',
	LANGUAGE_MODEL.En, '英文',
	LANGUAGE_MODEL.CnEn, '双语',
	LANGUAGE_MODEL.Cn2En, '中译英',
)

const SmsNotiWDocTypes = genOpts(
	SMSNOTIWDOC_TYPE.docUped,'内容已更新',
	SMSNOTIWDOC_TYPE.docRemarked,'有新评论与回复',
)

const DocStates = genOpts(
	DOC_STATE.draft, '起草中',
	DOC_STATE.remarking, '审阅中',
	DOC_STATE.fixed, '已定稿',
	DOC_STATE.issued, '已发布',
)

const DocFeeStates = genOpts(
	DOC_FEE_STATE.todo, '待完成',
	DOC_FEE_STATE.back, '打回',
	DOC_FEE_STATE.applied, '已请款',
	// DOC_FEE_STATE.waiting, '在等款',
	DOC_FEE_STATE.received, '已收款',
)

const DocFileWays = genOpts(
	Doc_FILE_WAY.gened, '生成',
	Doc_FILE_WAY.upload, '上传',
)

let rawExport = {
	SmsNotiWDocTypes,
	LanguageModels,
	RemarkStates,
	DocFeeStates,
	DocRoles,
	DocTypes,
	DocFileWays,
	PrjtStages,
	CrRoles,
	PrjtOrgRoles,
	OrgTypes,
	DeptTypes,
	SiteStates,
	MonTypes,
	MonDayStates,
	MonVeriStates,
	MonTopic_Classes,
	MonTopic_Answers,
	MvrStates,
	StoneCrucials,
	StoneLvs,
	StoneRoleTypes,
	StoneSuitStates,
	StoneTypes,
	SiteStages,
	SiteStoneStages,
	prjtStages,
	PrjtStoneStages,
	LawStates,
	LawTypes,
	OrgRegStates,
	Prodoucts,
	BriefStoneTypes,
	BriefStoneLvs,
	DocStates,
}

let {...finalExport} = rawExport
for (let p in rawExport) {
	finalExport[p+'Map'] = genMap(rawExport[p])
	finalExport[p+'SMap'] = genSMap(rawExport[p])
	finalExport[p+'SMap2Id'] = genSMap(rawExport[p],'id','name')
}

// module.exports = finalExport

export default finalExport
