import REQ from "../app/REQ";
import {classifyByProp, genMap, upWFoundById} from "../utils/xyhUtils"
import editDocDlg from '@/dialg/editDocDlg.vue'
import {mapGetters, mapState} from "vuex";
import {DOC_ROLE, DOC_STATE, DOC_TYPE} from "@/consts"
import docOptions from "./docOptions"
import editAnnexDlg from "@/dialg/editAnnexDlg.vue"
import rmDocDlg from "@/doc/rmDocDlg.vue";
import editDocDeptDlg from "@/doc/editDocDeptDlg.vue";
import selDeptDlg from "@/doc/selDeptDlg.vue";
import {wordViewUrl} from "@/doc/consts"
import Options from "@/Options";
import addDocStaffDlg from "@/doc/addDocStaffDlg.vue"

const {DocPathsSMap} = docOptions
const {DocTypesSMap} = Options

export default {
	name: "docDept",
	components:{ editDocDlg, editAnnexDlg, rmDocDlg, editDocDeptDlg, selDeptDlg, addDocStaffDlg, },
	props: ['id'],

	data() {
		return {
			docPaths:DocPathsSMap,
			dept: [],
			docs:[],
			annexes:[],
			depts:[],
			docDept: null,
			annexe:null,

			amIPM:false,
			amIAuthor:false,
			amIPrjtStaff:false,
			prjtStaffs:[],
			oldDoc:null,
			typeN:null,
			type:null,
			checks: {},
			isBatch:false,
			allDocs:[],
			filterWord1:'',
			filterWord2:'',
			order:{
				num:true,
			},
			sortingProperty:'',
			docStatus:'',
			docType:'unAnnex',
			planRmDoc: {},
			roleN:'authorN',
			filterOfRole:{
				authorN:'作者',
				auditorN:'审阅者',
				// translatorN:'翻译',
				allStaffN:'全部',
			},
		};
	},

	computed: {
		...mapState({
			orgId: ({ curOrg }) => curOrg?.id,
			myId: ({curStaff}) => curStaff?.id,
		}),
		...mapGetters(['amIAdmin']),

		displayDocs(){
			let {filterWord2, docStatus, docType, order, docs, roleN} = this
			if(filterWord2.length>0) docs = this.docs.filter(it=>
				it.name.toLowerCase().includes(filterWord2) ||
				it.num.toLowerCase().includes(filterWord2) ||
				(it[roleN] && it[roleN].toLowerCase().includes(filterWord2))
			)
			if(docStatus) docs = docs.filter(it=>it.state == docStatus)
			if(docType) docs = docs.filter(it => docType == 'unAnnex' ? it.type != DOC_TYPE.annex:it.type == docType)
			for(let i in order){
				if(order[i]) {
					docs.sort((doc1, doc2) => doc1[i].toLowerCase().localeCompare(doc2[i].toLowerCase()))
				}else {
					docs.sort((doc1, doc2) => doc2[i].toLowerCase().localeCompare(doc1[i].toLowerCase()))
				}
			}
			return docs
		},

		allSeled() {return this.displayDocs.every(it=>this.checks[it.id]);},

		isIndeterminate() { return  !(this.allSeled || this.displayDocs.every(it=>!this.checks[it.id]))},
	},

	created() {
		this.getDocDept()
	},

	methods: {
		getDocDept() {
			let {id} = this
			this.req2Data(REQ.docDept, id, null,({prjtStaffs,staffs,docs,tplts,docStaffs,prjt}) => {
				upWFoundById(docStaffs, staffs, "staffId", (docStaff, staff) => docStaff.staffN = staff.name)
				let docIdOfStaffs = classifyByProp(docStaffs, 'docId')
				docs.forEach(doc=>{
					let staffs = docIdOfStaffs[doc.id]
					if(staffs){
						let authors = staffs.filter(it=>it.role == DOC_ROLE.author)
						let auditors = staffs.filter(it=>it.role == DOC_ROLE.auditor)
						let translators = staffs.filter(it=>it.role == DOC_ROLE.translator)
						let allStaffs = Object.values(genMap(staffs, 'staffId'))
						doc.amIStaff = staffs.find(it=>it.staffId == this.myId) ? true:false
						doc.amIAuthor = authors.find(it=>it.staffId == this.myId) ? true:false
						doc.authorN = authors.map(it=>it.staffN).join('、')
						doc.auditorN = auditors.map(it=>it.staffN).join('、')
						doc.translatorN = translators.map(it=>it.staffN).join('、')
						doc.allStaffN = allStaffs.map(it=>it.staffN).join('、')
					}
				})
				this.amIPrjtStaff = prjtStaffs.find(it=>it.staffId == this.myId) ? true:false
				this.amIPM = prjtStaffs.find(it=>it.role == DOC_ROLE.PM && it.staffId == this.myId) ? true:false
				this.amIAuthor = prjtStaffs.find(it=>it.role == DOC_ROLE.author && it.staffId == this.myId) ? true:false
				upWFoundById(docs,tplts,'tpltId',(doc,tplt)=>doc.tpltN = tplt.name)
				docs.forEach(it=> {
					if(!it.type) it.type = DOC_TYPE.sop
					if(!it.state) it.state = DOC_STATE.remarking
					it.canPreview = it.tpltId || (it.type == DOC_TYPE.sop && prjt.sopTpltId) || (it.type == DOC_TYPE.policy && prjt.policyTpltId) || (it.type == DOC_TYPE.manual && prjt.manualTpltId)
					it.url = ((it.type == DOC_TYPE.annex || it.type == DOC_TYPE.file) && it.file) ? it.file:
						XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + it.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
				})
				this.allDocs = docs
			})
		},

		getDepts(){
			this.req2Data(REQ.docDeptsOfPrjt,{prjtId:this.dept.prjtId}, {depts:'depts'},()=>this.showDlg('selDeptDlg'))
		},

		batchAddDocStaff(staff){
			let docIds=[]
			let {checks, displayDocs} = this
			let {staffId, role} = staff
			displayDocs.forEach(it=> {
				if (checks[it.id]) docIds.push(it.id)
			})
			if(this.chk2msg(docIds.length>0, '请选择要分配角色的文档')) return
			let num = docIds.length
			docIds.forEach(it=>{
				this.req2Data(REQ.addDocStaff, {docId: it, staffId, role,}, {} ,()=> {
					num--
					if(num <= 0) this.getDocDept()
				})
			})
			this.isBatch=false
		},

		batchSelection(){
			let {allSeled, isIndeterminate, displayDocs} = this
			let s = !(isIndeterminate || allSeled)
			displayDocs.forEach(it => this.$set(this.checks, it.id, s))
		},

		addDoc(type){
			this.oldDoc = null
			this.typeN = DocTypesSMap[type]
			this.type = type
			this.showDlg('editDocDlg')
		},

		search() {
			this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
		},

		sorted(propertyN){
			this.sortingProperty = propertyN
			let order = !this.order[propertyN]
			delete this.order[propertyN]
			this.$set(this.order,propertyN,order)
		},

		roleFilter(role){
			this.$set(this,'roleN',role)
		},

		statusFilter(status){
			this.$set(this,'docStatus',status)
		},

		typeFilter(type){
			this.$set(this,'docType',type)
		},

		transferDoc(targetDept){
			let docIds=[]
			let {checks, displayDocs} = this
			displayDocs.forEach(it=> {
				if (checks[it.id]) docIds.push(it.id)
			})
			if(this.chk2msg(docIds.length>0, '请选择要转移的文档')) return
			this.req2Data(REQ.chgDeptForDocs,{deptId:targetDept.id,docIds},{}, ()=>this.getDocDept())
			this.isBatch=false
		},

		preview(url){
			window.open(wordViewUrl + encodeURIComponent(url))
		},

		editAnnex(annexe){
			this.annexe = annexe
			this.showDlg('editAnnexDlg')
		},

		editDocDept(docDept){
			this.docDept = docDept
			this.showDlg('editDocDeptDlg')
		},

		deleteDocDept(id){
			this.confirm('该操作将会删除此归口部门，确认吗？',()=>{
				this.req2Data(REQ.rmDocDept, {id},{}, ()=>this.getDocDept())
			})
		},

		deleteAnnex(id){
			this.confirm('该操作将会删除此支持文件，确认吗？',()=>{
				this.req2Data(REQ.rmDocAnnex, {id},{}, ()=>this.getDocDept())
			})
		},

		editDoc(oldDoc,type,typeN){
			this.oldDoc = oldDoc
			this.typeN = typeN
			this.type = type
			this.showDlg('editDocDlg')
		},

		deptDocExport(typeN, stateN) {
			const {export_json_to_excel} = require('@/vendor/Export2Excel');
			const tHeader = ['文档编号', '中文名称', '类型(' + typeN + ')', '状态(' + stateN + ')',];
			const filterVal = ['num', 'name','type', 'state',];
			const data = this.displayDocs.map(doc=> {
				let {...doc2} = doc
				doc2.type = this.DocTypesSMap[doc.type]
				doc2.state = this.DocStatesSMap[doc.state]
				return doc2
			}).map(v => filterVal.map(j => v[j]))
			export_json_to_excel(tHeader, data, `部门文档`);
		},
	},
};
