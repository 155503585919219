import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import depts from "@/components/department/depts";
import {genMap} from "@/utils/xyhUtils";

export default {
	name: "editDocDeptDlg",
	mixins: [formDlgMix],
	props:['prjtId', 'editDocDept', 'depts'],

	data() {
		return {
			name:'',
			name_E:'',
			parentId:'',
			parentChoices:[],
		}
	},

	watch: {
		working(){
			let {editDocDept, depts} = this
			if(editDocDept) {
				let childrenMap = genMap(editDocDept.childrens)
				this.parentChoices = depts.filter(it=>!childrenMap[it.id] && it.id !=editDocDept.id)
				Object.assign(this, {name: editDocDept.name, name_E: editDocDept.name_E, parentId: editDocDept.parentId})
			}else {
				Object.assign(this, {name: '', name_E:'', parentId: '', parentChoices:depts})
			}
		}
	},

	methods: {

		ok() {
			let {name, name_E, prjtId, editDocDept, parentId} = this
			if (this.chk2msg(name,'请输入部门名称')) return
			if(editDocDept){
				this.reqThenBk(REQ.upDocDept,{name, name_E, id:editDocDept.id, parentId})
			}else {
				this.reqThenBk(REQ.addDocDept,{name, name_E, prjtId, parentId})
			}
		}
	}
};
