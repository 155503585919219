import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import {DOC_ROLE} from "@/consts"

export default {
	name: "addDocStaffDlg",
	mixins: [formDlgMix],

	data() {
		return {
			staffId:'',
			ourStaffs: [],
			role:DOC_ROLE.auditor,
			roleDes: {
				author:'文档作者能够修改本文档的信息，管理本文档的成员，撰写审阅意见',
				translator:'文档翻译能够修改本文档的内容，撰写针对本文档内容部分的审阅意见',
				auditor:'文档审阅者能够针对本文档的内容撰写审阅意见',
			},
		}
	},

	watch: {
		working() {
			this.req2Data(REQ.ourStaffs,null,'ourStaffs')
			Object.assign(this, {staffId:'', role:DOC_ROLE.auditor,})
		}
	},

	methods: {
		ok() {
			let {staffId, role} = this
			this.$emit('oked', {role, staffId})
			this.working = false
		}
	}
};
