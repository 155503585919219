import { render, staticRenderFns } from "./docDept.vue?vue&type=template&id=2a91edfc&scoped=true&"
import script from "./docDept.js?vue&type=script&lang=js&"
export * from "./docDept.js?vue&type=script&lang=js&"
import style0 from "./docDept.less?vue&type=style&index=0&id=2a91edfc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a91edfc",
  null
  
)

export default component.exports