import { REQ, REQP } from '../../services/config'
import VueDatepickerLocal from 'vue-datepicker-local'



export default {
    name: 'departments',

    data() {
        return {
            departments: [],
            newDept:'',
    }},

    created() {
        this.token = this.getCookie('token')
        this.getDepartments()
    },

    components: { VueDatepickerLocal },

    methods: {

        getDepartments() {
		    this.authedReqR(REQ.ourDepts, null, res => this.departments = res )
        },

        addDept(){
		    this.authedReq(REQP.addDept, {name:this.newDept}, res => {
		        this.getDepartments()
		        this.newDept = ''
		    })
        }
    },

}
